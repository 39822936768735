<template>
	<v-card flat class="">
		<v-data-table :headers="table.headers" :items="table.items" :options.sync="tableOptions"
			:server-items-length="table.totalItems" :loading="table.loading" dense class="elevation-1">
			<template v-slot:top>
				<v-toolbar flat>
					<v-toolbar-title>Master Pilihan Praktik/Stase</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-dialog v-model="dialog" max-width="500px">
						<template v-slot:activator="{ on, attrs }">
							<!-- <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="add()">
								Tambah Item
							</v-btn> -->
						</template>
						<v-card>
							<v-card-title>
								<span class="text-h5">{{ formTitle }}</span>
							</v-card-title>
							<v-card-text>
								<v-row>
									<v-col cols="12">
										<v-text-field v-model="form.editedItem.nama" outlined dense hide-details=""
											label="Nama :"></v-text-field>
									</v-col>
								</v-row>
							</v-card-text>
							<v-card-actions>
								<v-btn color="primary" @click="save()">
									Simpan
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
					<!-- <v-dialog v-model="dialogDelete" max-width="500px">
						<v-card>
							<v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="blue darken-1" @click="closeDelete">Cancel</v-btn>
								<v-btn color="blue darken-1" @click="deleteItemConfirm">OK</v-btn>
								<v-spacer></v-spacer>
							</v-card-actions>
						</v-card>
					</v-dialog> -->
				</v-toolbar>
			</template>
			<template v-slot:item.actions="{ item }">
				<!-- <v-icon small class="mr-2" @click="editItem(item)">
					{{ icons.mdiPencil }}
				</v-icon> -->
			</template>
			<template v-slot:no-data>
				<v-btn color="primary" @click="getDataFromApi">
					Reload
				</v-btn>
			</template>
		</v-data-table>
	</v-card>
</template>

<script>
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'
import { mdiEye, mdiPencil } from '@mdi/js'

const apiRoot = process.env.VUE_APP_APIROOT
export default {
	components: {
	},
	data: () => ({
		icons: {
			mdiEye,
			mdiPencil,
		},
		tableOptions: {},
		table: {
			loading: true,
			headers: [
				{ text: 'No', align: 'start', sortable: false, value: 'no' },
				{ text: 'Kode', align: 'start', sortable: false, value: 'kode' },
				{ text: 'Nama', align: 'start', sortable: false, value: 'nama' },
				{ text: 'Stase', align: 'start', sortable: false, value: 'stase' },
				{ text: 'Aksi', align: 'start', sortable: false, value: 'actions' },
			],
			items: [],
			totalItems: 0,
		},
		dialog: false,
		form: {
			editedIndex: -1,
			editedItem: {
				id: '',
				nama: ''
			},
			defaultItem: {
				id: '',
				nama: ''
			},
		}
	}),
	watch: {
		tableOptions: {
			handler() {
				this.getDataFromApi()
			},
			deep: true,
		},
	},
	computed: {
		formTitle() {
			return this.form.editedIndex === -1 ? 'Tambah Item' : 'Edit Item'
		},
		// user() {
		// 	return this.$store.getters.user
		// },
	},
	created() {
	},
	methods: {
		save() {
			if (this.form.editedIndex > -1) {
				const fmData = new FormData()
				fmData.append('id', this.form.editedItem.id)
				fmData.append('nama', this.form.editedItem.nama)
				axiosPostAuth('api/Universitas/updateProdi', fmData)
					.then(response => {
						if (response.code === 200) {
							alert(response.message)
							this.dialog = false
							this.getDataFromApi()
						}
						else {
							alert(response.message)
						}
					})
					.catch(error => {
						alert(error)
					})
			} else {
				const fmData = new FormData()
				fmData.append('nama', this.form.editedItem.nama)
				axiosPostAuth('api/Universitas/addProdi', fmData)
					.then(response => {
						if (response.code === 200) {
							alert(response.message)
							this.getDataFromApi()
						}
						else {
							alert(response.message)
						}
					})
					.catch(error => {
						alert(error)
					})
			}
		},
		getDataFromApi() {
			const { page, itemsPerPage } = this.tableOptions
			const fmData = new FormData()
			fmData.append('page', page)
			fmData.append('itemsPerPage', itemsPerPage)
			fmData.append('search', '')
			axiosPostAuth('api/Master/getStase_datatable', fmData)
				.then(response => {
					this.table.items = response.data
					this.table.totalItems = response.total
					this.table.loading = false
				})
				.catch(error => {
					alert(error)
				})
		},
		add() {
			this.form.editedItem = Object.assign({}, this.form.defaultItem)
			this.form.editedIndex = -1
		},
		editItem(item) {
			this.form.editedIndex = this.table.items.indexOf(item)
			this.form.editedItem = Object.assign({}, item)
			this.dialog = true
		}
	},
}
</script>